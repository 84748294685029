import { injectIntl } from 'react-intl';
import moment from 'moment';

import { isSaudi } from 'constants/helper';
import { DEPARTMENTS } from 'constants/compensation';
import { MAP_DELIVERY_TYPES } from 'constants/Deliveries';

import './PriceBreakdownToolTip.less';

const PriceBreakdownToolTip = ({
  isCredit = false,
  intl,
  liabilityFactor = false,
  totalCreditAmount,
  deliveryInfo,
  creditPricingInfo
}) => {
  const { cod, type, state, pricing, pricingPackageSize, confirmedAt } =
    deliveryInfo || {};

  const creditPricingInfoItems = creditPricingInfo?.map(
    ({ departmentName, liability }) => {
      return {
        label: DEPARTMENTS[departmentName]?.name,
        value: intl.formatMessage(
          { id: `common.${isSaudi() ? 'SAR' : 'EGP'}_modified` },
          {
            cod: liability?.liabilityAmount || 0
          }
        ),
        amount: liability?.liabilityAmount || 0
      };
    }
  );

  const orderInfoBreakDownItems = [
    {
      label: intl.formatMessage({
        id: 'wallet.compensation.amount_tooltip.order_type'
      }),
      value: MAP_DELIVERY_TYPES[type] || MAP_DELIVERY_TYPES.SEND
    },
    {
      label: intl.formatMessage({
        id: 'wallet.compensation.amount_tooltip.status'
      }),
      value: state?.value
    },
    {
      label: intl.formatMessage({
        id: 'wallet.compensation.amount_tooltip.package_size'
      }),
      value:
        pricingPackageSize?.alias === 'XXL'
          ? `${pricingPackageSize?.alias} (${pricingPackageSize?.name})`
          : pricingPackageSize?.name
    },
    {
      label: intl.formatMessage({
        id: 'wallet.compensation.amount_tooltip.deliverd_at'
      }),
      value: state?.delivering?.time
        ? moment(state?.delivering?.time).format('MMM d, YYYY hh:mm A')
        : 'N/A'
    },
    {
      label: intl.formatMessage({
        id: 'wallet.compensation.amount_tooltip.confirmed_at'
      }),
      value: confirmedAt || 'N/A'
    },
    {
      label: intl.formatMessage({
        id: 'wallet.compensation.amount_tooltip.cod'
      }),
      value: cod?.amount ? `${pricing?.currency} ${cod?.amount}` : 0
    }
  ];

  const breakDownItems = isCredit
    ? [...creditPricingInfoItems]
    : [
        {
          label: intl.formatMessage({
            id: 'wallet.compensation.amount_tooltip.basic_fees'
          }),
          value: `${pricing?.currency} ${
            pricing?.shippingFee - pricing?.size?.cost || 0
          }`
        },
        {
          label: intl.formatMessage({
            id: 'wallet.compensation.amount_tooltip.package_size_fees'
          }),
          value: `${pricing?.currency} ${pricing?.size?.cost ?? 0}`
        },
        {
          label: intl.formatMessage({
            id: 'wallet.compensation.amount_tooltip.insurance_fees'
          }),
          value: `${pricing?.currency} ${pricing?.insuranceFee?.amount ?? 0}`
        },
        {
          label: intl.formatMessage({
            id: 'wallet.compensation.amount_tooltip.collection_fees'
          }),
          value: `${pricing?.currency} ${
            (pricing?.extraCodFee?.codAmount || pricing?.extraCodFee?.amount) ??
            0
          }`
        },
        {
          label: intl.formatMessage({
            id: 'wallet.compensation.amount_tooltip.next_day_fees'
          }),
          value: `${pricing?.currency} ${pricing?.expediteFee?.amount ?? 0}`
        },
        {
          label: intl.formatMessage({
            id: 'wallet.compensation.amount_tooltip.zero_cod_discount'
          }),
          value: `${pricing?.currency} ${
            pricing?.zeroCodDiscount?.amount ? '-' : ''
          } ${pricing?.zeroCodDiscount?.amount ?? 0}`
        },
        {
          label: intl.formatMessage({
            id: 'wallet.compensation.amount_tooltip.promotion_discount'
          }),
          value: `${pricing?.currency} ${
            pricing?.promotion?.amount ? '-' : ''
          }${pricing?.promotion?.amount ?? 0}`
        },
        {
          label: intl.formatMessage({
            id: 'wallet.compensation.amount_tooltip.total_before_vat'
          }),
          value: `${pricing?.currency} ${pricing?.priceBeforeVat ?? 0}`
        },
        {
          label: intl.formatMessage(
            {
              id: 'wallet.compensation.amount_tooltip.vat'
            },
            { amount: parseInt(pricing?.vat * 100) || 14 }
          ),
          value: `${pricing?.currency} ${
            Math.round(
              (pricing?.priceAfterVat - pricing?.priceBeforeVat) * 100
            ) / 100 ?? 0
          }`
        }
      ];

  const finalBreakDownItems = deliveryInfo
    ? [...orderInfoBreakDownItems, ...breakDownItems]
    : breakDownItems;

  return (
    <div className="price-breakdown-container">
      {finalBreakDownItems.map(
        (item, id) =>
          ((isCredit && item.amount) || !isCredit) && (
            <div key={id} className="price-breakdown-item-content">
              <span>{item.label}</span>
              <span>{item.value}</span>
            </div>
          )
      )}
      <div className="price-breakdown-total-amount">
        <span>Total {!isCredit && 'After VAT'}</span>
        <span>
          {!isCredit
            ? `${liabilityFactor ? `${liabilityFactor} x ` : ''} ${
                pricing?.currency
              } ${Math.round(pricing?.priceAfterVat * 100) / 100 ?? 0}`
            : `${intl.formatMessage(
                { id: `common.${isSaudi() ? 'SAR' : 'EGP'}_modified` },
                {
                  cod: totalCreditAmount
                }
              )}`}
        </span>
      </div>
    </div>
  );
};

export default injectIntl(PriceBreakdownToolTip);
