import { injectIntl } from 'react-intl';

import { isSaudi } from 'constants/helper';

import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';

import { ReactComponent as MenuIcon } from 'assets/bosta-icons/Menu.svg';

import './BusinessInfoSection.less';

const userInfo = JSON.parse(localStorage.getItem('userInfo'));
const ADMIN_NAME = `${userInfo?.profile?.firstName || ''} ${
  userInfo?.profile?.lastName || ''
}`;
const ADMIN_EMAIL = userInfo?.emails?.length ? userInfo.emails[0]?.address : '';
const BusinessInfoSection = ({
  intl,
  delivery: { sender },
  totalAmount,
  amountLoading
}) => {
  return (
    <div className="br-business-info-content-container">
      <div className="br-compensation__form-section-header">
        <MenuIcon /> Request details
      </div>
      <div className="br-compensation__business-info-rows">
        <div className="br-business-info-container">
          <span className="br-business-info__header">
            {intl.formatMessage({
              id: 'request_compensation_modal.business_info_title'
            })}
          </span>
          <div className="br-business-info-content">
            <div className="br-business-info-content-text br-business-info-content-text-label">
              <span>
                {intl.formatMessage({
                  id: 'request_compensation_modal.business_name'
                })}
              </span>
              <span>
                {intl.formatMessage({
                  id: 'request_compensation_modal.business_id'
                })}
              </span>
            </div>
            <div className="br-business-info-content-text br-business-info-content-value">
              <span>{sender?.name}</span>
              <span>{sender?._id}</span>
            </div>
          </div>
        </div>
        <div className="br-business-info-container submitter-info-section">
          <span className="br-business-info__header">
            {intl.formatMessage({
              id: 'request_compensation_modal.submitter_info_title'
            })}
          </span>
          <div className="br-business-info-content">
            <div className="br-business-info-content-text br-business-info-content-text-label">
              <span>
                {intl.formatMessage({
                  id: 'request_compensation_modal.submitted_by'
                })}
              </span>
              <span className="br-business-info-email-text">
                {intl.formatMessage({
                  id: 'request_compensation_modal.email'
                })}
              </span>
            </div>
            <div className="br-business-info-content-text br-business-info-content-value">
              <span>{ADMIN_NAME}</span>
              <span className="br-business-info-email-text">{ADMIN_EMAIL}</span>
            </div>
          </div>
        </div>
      </div>

      <div className="br-business-info__amount-container">
        <span className="br-business-info__header">
          {intl.formatMessage({
            id: 'request_compensation_modal.total_amount'
          })}
        </span>
        <LoadingWrapper loading={amountLoading}>
          <span className="br-amount-text">
            {isSaudi() ? 'SAR' : 'EGP'}{' '}
            {Math.round(totalAmount * 100) / 100 ?? 0}
          </span>
        </LoadingWrapper>
      </div>
    </div>
  );
};

export default injectIntl(BusinessInfoSection);
