import classNames from 'classnames';

import DatePicker from 'components/OpsControl/FilterComponents/DatePicker';
import HubSelection from 'components/OpsControl/FilterComponents/HubSelection';

import './PendingTransitFilter.less';

const PendingTransitFilter = ({
  intl,
  selectedDate,
  allHubs,
  currentHub,
  setCurrentHub,
  loading,
  handleHubChange,
  changeDateValue
}) => {
  return (
    <div
      className={classNames('br-pending-transit-filter__container', {
        'br-pending-transit-filter__hidden-hubs-container': !allHubs
      })}
    >
      {allHubs && (
        <HubSelection
          handleHubChange={handleHubChange}
          loading={loading}
          intl={intl}
          allHubs={allHubs}
          setCurrentHub={setCurrentHub}
          currentHub={currentHub}
        />
      )}
      <DatePicker
        loading={loading}
        selectedDate={selectedDate}
        setSelectedDate={changeDateValue}
      />
    </div>
  );
};
export default PendingTransitFilter;
