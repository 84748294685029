import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/ie11'; // For IE 11 support
import './polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import utc from 'dayjs/plugin/utc';
import duration from 'dayjs/plugin/duration';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import dayjsBusinessDays from 'dayjs-business-days2';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import IntlWrapper, { intl } from 'IntlWrapper/IntlWrapper';
import App from './App';
import store from './store/configureStore';
import * as serviceWorker from './serviceWorker';
import { WrapperProvider } from 'contexts/wrapper.context';

const validateMessages = {
  required: intl.formatMessage({ id: 'form.required' }),
  string: {
    max: intl.formatMessage({ id: 'form.maxLen' })
  },
  whitespace: intl.formatMessage({ id: 'form.required' })
};

dayjs.extend(utc);
dayjs.extend(localizedFormat);
dayjs.extend(duration);
dayjs.extend(isSameOrAfter);
dayjs.extend(dayjsBusinessDays);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);
dayjs.extend(isToday);
dayjs.extend(isYesterday);
dayjs.extend(isTomorrow);

ReactDOM.render(
  <Provider store={store}>
    <IntlWrapper>
      <ConfigProvider form={{ validateMessages }}>
        <WrapperProvider>
          <App />
        </WrapperProvider>
      </ConfigProvider>
    </IntlWrapper>
  </Provider>,
  document.getElementById('root')
);

if (process.env.NODE_ENV === 'production') console.log = () => {};
// If you want your app to work offline and load faster, you can changes
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
