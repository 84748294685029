import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Form, Tooltip } from 'antd';
import Icon from '@ant-design/icons';

import { FILE_EXTENTIONS, MAX_ITEM_VALUE } from 'constants/shipments';
import { getCurrency } from 'common/countries/countries-mapping';
import { rangeRule } from 'utils/forms';
import { openModal } from 'utils/modal';
import { NUMBER_TEXT } from 'constants/form';

import BRButton from 'components/BRButton/BRButton';
import BRFormInputs from 'components/BRFormInputs/BRFormInputs';
import UploadAttachment from 'components/SupportTickets/components/UploadAttachment/UploadAttachment';
import GoodsTermsModal from 'components/Shipments/CreateEditOrder/OrderDetails/ItemValue/GoodsTermsModal';
import PreviewImage from 'components/SupportTickets/components/PreviewImage/PreviewImage';

import UploadIcon from 'assets/bosta-icons/upload-package.svg';
import { ReactComponent as TooltipIcon } from 'assets/bosta-icons/Tooltip.svg';
import { ReactComponent as DocumentIcon } from 'assets/bosta-icons/Document.svg';
import { ReactComponent as EyeIcon } from 'assets/bosta-icons/Eye.svg';
import { ReactComponent as DeleteIcon } from 'assets/bosta-icons/Delete.svg';

import './ItemValue.less';

const ItemValue = ({ intl, initialValues, formRef }) => {
  const { amount, proofOfCostAttachmentUrl, proofOfCostAttachmentType } =
    initialValues || {};
  const extension = proofOfCostAttachmentType?.split('/')[1];

  const [isPreview, setIsPreview] = useState(false);

  const handleDeleteImage = () => {
    setIsPreview(false);
  };

  const handleOpenPreview = (photo) => {
    if (photo) {
      openModal(PreviewImage, {
        previewImage: photo
      });
    }
  };

  const handleTerms = () => {
    openModal(GoodsTermsModal);
  };

  useEffect(() => {
    if (amount) {
      formRef.current?.setFieldsValue({
        itemValue: amount
      });
    }

    setIsPreview(!!proofOfCostAttachmentUrl);
  }, [amount]);

  return (
    <div className="br-good-value__form-group">
      <BRFormInputs
        formRef={formRef}
        name="itemValue"
        label={
          <div className="br-item-value__value-label">
            <div>
              {intl.formatMessage({
                id: `shipments.new_order.form_labels.item_value`
              })}
              <Tooltip
                overlayClassName="br-item-values__tooltips-parent"
                getPopupContainer={(trigger) =>
                  trigger.parentElement.parentElement.parentElement
                    .parentElement.parentElement.parentElement.parentElement
                    .parentElement.parentElement.parentElement
                }
                title={
                  <div className="br-item-values__tooltips">
                    <p className="body-md">
                      {intl.formatMessage({
                        id: `shipments.new_order.tooltips.item_value_tooltip_title`
                      })}
                    </p>
                    <p>
                      {intl.formatMessage({
                        id: `shipments.new_order.tooltips.item_value_tooltip`
                      })}
                    </p>
                  </div>
                }
              >
                <Icon component={TooltipIcon} className="ant-icon-sm" />
              </Tooltip>
              <span className="br-form-optional-label">
                {intl.formatMessage({
                  id: 'form.optional_label'
                })}
              </span>
            </div>
            <BRButton
              label={intl.formatMessage({
                id: 'shipments.new_order.terms_and_conditions'
              })}
              onClick={handleTerms}
              type="link-color"
            />
          </div>
        }
        rules={[
          rangeRule({
            message: intl.formatMessage(
              { id: 'shipments.new_order.item_value_err' },
              {
                max: MAX_ITEM_VALUE,
                currency: getCurrency().type
              }
            ),
            max: MAX_ITEM_VALUE - 1
          })
        ]}
        placeholder="0.00"
        type={NUMBER_TEXT}
        acceptFractions={true}
        prefix={getCurrency().type}
      />

      {isPreview ? (
        extension === FILE_EXTENTIONS.PDF ? (
          <a
            target="_blank"
            href={proofOfCostAttachmentUrl}
            alt="proof_of_value"
          >
            <DocumentIcon />
          </a>
        ) : (
          <div className="br-form__proof-preview-container">
            <img
              className="br-form__proof-image-preview"
              alt="proofOfCostAttachmentUrl"
              src={proofOfCostAttachmentUrl}
            />
            <div>
              <EyeIcon
                onClick={() => {
                  handleOpenPreview(proofOfCostAttachmentUrl);
                }}
              />
              <DeleteIcon
                onClick={() => {
                  handleDeleteImage();
                }}
              />
            </div>
          </div>
        )
      ) : (
        <Form.Item
          formRef={formRef}
          label={
            <div>
              {intl.formatMessage({
                id: `shipments.new_order.form_labels.value_proof`
              })}
              <Tooltip
                overlayClassName="br-item-values__tooltips-parent"
                getPopupContainer={(trigger) =>
                  trigger.parentElement.parentElement.parentElement
                    .parentElement.parentElement.parentElement.parentElement
                    .parentElement.parentElement.parentElement
                }
                title={
                  <div className="br-item-values__tooltips">
                    <p className="body-md">
                      {intl.formatMessage({
                        id: `shipments.new_order.tooltips.value_proof_tooltip_title`
                      })}
                    </p>
                    <p>
                      {intl.formatMessage({
                        id: `shipments.new_order.tooltips.value_proof_tooltip`
                      })}
                    </p>
                  </div>
                }
              >
                <Icon component={TooltipIcon} className="ant-icon-sm" />
              </Tooltip>

              <span className="br-form-optional-label">
                {intl.formatMessage({
                  id: 'form.optional_label'
                })}
              </span>
            </div>
          }
          name="valueProof"
        >
          <UploadAttachment
            maxCount={1}
            accept=".jpg, .jpeg, .png, .pdf"
            customSubtitle={intl.formatMessage({
              id: 'shipments.new_order.proof_value_upload_support'
            })}
            uploadIcon={UploadIcon}
            maxSize={5}
            disableEncoding
            hideUploadButtonAfterMaxCount
          />
        </Form.Item>
      )}
    </div>
  );
};

export default injectIntl(ItemValue);
