import classNames from 'classnames';

import BRButton from 'components/BRButton/BRButton';

import './BRToggleButton.less';
const BRToggleButton = ({
  buttonsList,
  value,
  loading,
  changeToggleButton
}) => {
  const renderButtons = () => {
    return buttonsList.map((button, index) => {
      return (
        <BRButton
          disabled={loading}
          onClick={() => changeToggleButton(button.value)}
          type={button.value === value ? 'primary' : 'basic'}
          className={classNames(
            'br-toggle-button__br-singlebutton',
            'body-medium',
            {
              'br-toggle-button__br-rounded-start-button': !index
            },
            {
              'br-toggle-button__br-rounded-end-button':
                index === buttonsList.length - 1
            }
          )}
          label={button.title}
        />
      );
    });
  };
  return (
    <div className="br-toggle-button__br-toggle-button-container">
      {renderButtons()}
    </div>
  );
};
export default BRToggleButton;
