import http from 'utils/http';

export const getComepnsationReasons = async () => {
  return http.get('wallet/compensation/requests/reasons');
};

export const getDepartmentsApprovals = async (payload) => {
  return http.get('wallet/compensation/requests/departments', payload);
};

export const getDepartmentsApprovalsOld = async () => {
  return http.get('/wallet/compensation/departments');
};

export const calculateCompensationAmount = (payload) => {
  return http.post('/wallet/compensation/request/calculate-amount', payload);
};

export const getCompensationDeliveryDetails = (id) => {
  return http.get(`/wallet/compensation/delivery-info/${id}`);
};

export const getBudgetReport = (month) => {
  return http.get(`/wallet/compensation/budget-report?budgetPeriod=${month}`);
};

export const getBudgetReportLogs = (month) => {
  return http.get(
    `/wallet/compensation/budget-report/logs?budgetPeriod=${month}`
  );
};

export const updateDepartmentBudget = (payload) => {
  return http.post('/wallet/compensation/departments/assigned-budget', payload);
};

export const getCompensationRecordsCount = (payload) => {
  return http.get(`/wallet/compensation/requests/count`, payload);
};
