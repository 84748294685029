import { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Collapse } from 'antd';
import dayjs from 'dayjs';

import { CALL_TYPES, STAR_ACTIONS } from 'constants/shipment-details';
import { convertSecondsToTime } from 'utils/helpers';
import { getOrderCallLogs } from 'services/shipments';

import { notify } from 'components/Notify/Notify';

import { ReactComponent as PhoneIcon } from 'assets/imgRevamp/call-calling.svg';
import { ReactComponent as SmsIcon } from 'assets/imgRevamp/sms.svg';
import { ReactComponent as WhatsAppIcon } from 'assets/bosta-icons/whatsapp.svg';

import './CallAndSmsLogs.less';

const CallLogs = ({ deliveryDetails, pickupDetails, intl }) => {
  const [callLogs, setCallLogs] = useState([]);
  const [smsLogs, setSmsLogs] = useState([]);
  const [whatsAppLogs, setWhatsAppLogs] = useState([]);
  const [activePanel, setActivePanel] = useState(null);

  useEffect(() => {
    if (deliveryDetails?.trackingNumber || pickupDetails?.puid) {
      getCallLogs();
    }
  }, [deliveryDetails, pickupDetails]);

  const handleButtonClick = ({ key }) => {
    if (activePanel === key) {
      return setActivePanel(null);
    }
    setActivePanel(key);
  };

  const getCallLogs = async () => {
    const payload = {
      ...(deliveryDetails && {
        trackingNumber: deliveryDetails.trackingNumber
      }),
      ...(pickupDetails && { pickupId: pickupDetails.puid })
    };

    try {
      const {
        data: { actions }
      } = await getOrderCallLogs(payload);

      const smsLogs = [];
      const whatsAppLogs = [];

      const callLogs = actions.filter((log) => {
        if (log.action === STAR_ACTIONS.SMS) {
          smsLogs.push(log);
        } else if (log.action === STAR_ACTIONS.WHATSAPP) {
          whatsAppLogs.push(log);
        }
        return Object.keys(CALL_TYPES).includes(log.action);
      });

      setCallLogs(callLogs);
      setSmsLogs(smsLogs);
      setWhatsAppLogs(whatsAppLogs);
    } catch (error) {
      notify(error.message);
    }
  };

  return (
    (!!callLogs.length || !!smsLogs.length || !!whatsAppLogs.length) && (
      <div className="br-delivery-logs__call-logs-container">
        <div className="br-delivery-logs-title">
          {intl.formatMessage({
            id: 'deliveries.delivery_details.call_logs.title'
          })}
        </div>

        <div className="br-delivery-logs__call-logs">
          <div
            className="br-delivery-logs__call-log-box"
            onClick={() => handleButtonClick({ key: 1 })}
          >
            <PhoneIcon />
            {intl.formatMessage({
              id: 'deliveries.delivery_details.call_logs.call_log'
            })}
            <span className="br-delivery-logs-log-count">
              {callLogs?.length}
            </span>
          </div>
          <div
            className="br-delivery-logs__call-log-box"
            onClick={() => handleButtonClick({ key: 2 })}
          >
            <SmsIcon />
            {intl.formatMessage({
              id: 'deliveries.delivery_details.call_logs.sms'
            })}
            <span className="br-delivery-logs-log-count">
              {smsLogs?.length}
            </span>
          </div>
          <div
            className="br-delivery-logs__call-log-box"
            onClick={() => handleButtonClick({ key: 3 })}
          >
            <WhatsAppIcon />
            {intl.formatMessage({
              id: 'deliveries.delivery_details.call_logs.whatsapp'
            })}
            <span className="br-delivery-logs-log-count">
              {whatsAppLogs?.length}
            </span>
          </div>
        </div>
        <Collapse accordion ghost activeKey={activePanel}>
          <Collapse.Panel showArrow={false} key="1">
            {callLogs?.map((log) => (
              <div className="br-delivery-call-log">
                {`${CALL_TYPES[log.action](log?.callDuration)}  ${
                  log?.callDuration && parseFloat(log?.callDuration)
                    ? `- ${convertSecondsToTime(log?.callDuration)}`
                    : ''
                } - ${dayjs(log.date)
                  .tz()
                  .format('dddd, DD MMM YYYY - h:mm A')} - ${log.starName}`}
              </div>
            ))}
          </Collapse.Panel>
          <Collapse.Panel showArrow={false} key="2">
            {smsLogs?.map((log) => (
              <div className="br-delivery-call-log">
                {`${dayjs(log.date)
                  .tz()
                  .format('dddd, DD MMM YYYY - h:mm A')} - ${log.starName}`}
                {log.smsBody && (
                  <div className="br-delivery-logs__extra-info">
                    {log.smsBody}
                  </div>
                )}
              </div>
            ))}
          </Collapse.Panel>
          <Collapse.Panel showArrow={false} key="3">
            {whatsAppLogs?.map((log) => (
              <div className="br-delivery-call-log">
                {`${intl.formatMessage({
                  id: 'deliveries.delivery_details.call_logs.whatsapp_message'
                })} - ${dayjs(log.date)
                  .tz()
                  .format('dddd, DD MMM YYYY - h:mm A')} - ${log.starName}`}
              </div>
            ))}
          </Collapse.Panel>
        </Collapse>
      </div>
    )
  );
};

export default injectIntl(CallLogs);
