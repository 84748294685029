import { fmt } from 'components/IntlWrapper/IntlWrapper';

export const ORDER_TYPES_VALUE = {
  CRP: 'CUSTOMER_RETURN_PICKUP',
  EXCHANGE: 'EXCHANGE',
  DELIVER: 'SEND',
  SIGN_AND_RETURN: 'SIGN_AND_RETURN',
  RTO: 'RTO',
  CASH_COLLECTION: 'CASH_COLLECTION',
  FXF_SEND: 'FXF_SEND'
};

export const CANCELLED = 'Canceled';
export const EXCEPTION = 'Exception';

export const SIGN_AND_RETURN_PICKUP = 'Sign and Return Pickup';

export const SATURDAY = 'Saturday';
export const FRIDAY = 'Friday';

export const AlLOWED_USER_TO_PUSH_UP_PRIORITY = [
  // 'CUSTOMER_SUPPORT',
  'SUPER_ADMIN'
];

export const STAR_ACTIONS = {
  CALL: 'CALL',
  SMS: 'SMS',
  WHATSAPP: 'WHATSAPP'
};

export const CALL_TYPES = {
  CALL: (duration) =>
    fmt({
      id: `deliveries.delivery_details.call_logs.outgoing_call${
        duration && parseFloat(duration) ? '' : '_no_answer'
      }`
    }),
  MISSED_CALL: (duration) =>
    fmt({ id: 'deliveries.delivery_details.call_logs.missed_call' }),
  INCOMING_CALL: (duration) =>
    fmt({
      id: `deliveries.delivery_details.call_logs.incoming_call${
        duration && parseFloat(duration) ? '' : '_no_answer'
      }`
    })
};

export const DELIVERY_DETAILS_SECTIONS = {
  CONSIGNEE_RATE: 'CONSIGNEE_RATE'
};
