import { fmt } from 'IntlWrapper/IntlWrapper';

export const BUDGET_STATE_MAPPER = {
  over: 'exception'
};

export const DEPARTMENTS = {
  Customer_Experience: {
    name: fmt({ id: 'budget_report.deps.customer_experience' }),
    value: 'Customer_Experience'
  },
  OPs: {
    name: fmt({ id: 'budget_report.deps.operations' }),
    value: 'OPs'
  },
  Commercial: {
    name: fmt({ id: 'budget_report.deps.commercial' }),
    value: 'Commercial'
  },
  Contractors: {
    name: fmt({ id: 'budget_report.deps.contractors' }),
    value: 'Contractors'
  }
};

export const OVERALL_PENDING_KEY = 'overallPending';

export const BUDGET_CHANGE_DATE_FORMAT = 'ddd, MMMM D, YYYY hh:mm A';

export const BUDGET_START_DATE = '2024-06-01';

export const GAUGE_BG_COLOR = '#E8E8E8';

export const INVESTIGATION = 'Investigation';

export const GAUGE_COLORS = {
  RED: '#e30613',
  DARK_RED: '#B42318',
  GREEN: '#039855',
  ORANGE: '#dc6803'
};

export const ORDER_TYPE = {
  SINGLE: 'single',
  MULTIPLE: 'multiple',
  ALL: ['single', 'multiple']
};

export const COMPENSATION_TYPE = {
  FREE: 'FREE',
  CREDIT: 'CREDIT'
};

export const DISPLAYED_COMPENSATION_TYPE = {
  FREE: 'Free Orders',
  CREDIT: 'Credit'
};

export const LIABILITY_TYPE = {
  HUB: 'Hub',
  OPS: 'OPS Budget'
};

export const LARGE_AMOUNT_APPROVAL = {
  MD: 5000,
  CFO: 10000
};

export const COMPENSATION_REASONS = {
  WRONG_PACKAGE_SIZE: 'Wrong Package Size'
};

export const SIZE_CONFIRMATION_OPTIONS = [
  {
    value: 'Normal',
    label: 'Normal'
  },
  {
    value: 'Large',
    label: 'Large'
  },
  {
    value: 'X-Large',
    label: 'X-Large'
  },
  {
    value: 'XXL (White Bag)',
    label: 'XXL (White Bag)'
  },
  {
    value: 'Light Bulky',
    label: 'Light Bulky'
  }
];

export const MIN_TN_LENGTH = 5;

export const COMPENSATION_TABLE_APPROVAL_TYPES = {
  DEPARTMENT: 'departmentName',
  ASSIGNED_ON: 'assignedOn'
};
