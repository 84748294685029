import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { get as getValue, omitBy } from 'lodash';
import Container from 'components/Container';
import { Accordion, Form, Col, Button } from 'react-bootstrap';
import { Table } from 'antd';
import { Formik } from 'formik';
import dayjs from 'dayjs';
import Select from 'react-select';
import styles from './styles.module.scss';
// import 'antd/es/pagination/style/index.css';
// import 'antd/es/table/style/index.css';
import Calendar from '../../components/Calendar';
import { notify } from 'components/Notify/Notify';
import {
  getVerifiedStars,
  getHubs,
  getMoneyDebriefReport,
  clearCurrentMoneyDebriefReport
} from '../../actions';
import { moneyDebriefReportColumns, ALL_HUBS } from './constants';
import { FAWRY_POS_SOURCE } from 'constants/stars';
import { FORMAT_DATE_TO_UTC, isDefaultBostaCourier } from 'utils/helpers';
import { exportMoneyDebreifReport } from 'services/money-debrief-report';
import HubManagmentContainer from './HubManagmentContainer';
import BRHeader from 'components/BRHeader/BRHeader';
import aclMatrix from 'common/aclMatrix';
import { getMoneyDebriefReportDepositTypes } from 'common/countries/countries-mapping';

import './moneyDebreifingReport.less';

const userInfo = JSON.parse(localStorage.getItem('userInfo'));
/**
 * format options to be bound in select controls
 * @param keyFieldName
 * @param labelFieldName
 * @param data
 * @returns {*}
 */
const formatOptions = (keyFieldName, labelFieldName, data) => {
  if (keyFieldName && labelFieldName) {
    const labelFields = labelFieldName.split(',');
    return data.map((item) => {
      const labelValue = labelFields.reduce((acc, value) => {
        if (!value) return acc;
        return `${acc} ${getValue(item, value, '')}`;
      }, '');

      return { value: item[keyFieldName], label: labelValue };
    });
  }
  return data.map((item) => ({ value: item, label: item }));
};

/**
 * format data read for binding in table
 * @param data
 * @param stars
 * @returns {*}
 */
const formatDataSource = (data, { stars }) => {
  return data.map(
    ({
      _id,
      starId,
      createdAt,
      src,
      amount,
      receiptNo,
      receiptDate,
      trackingNumber,
      additionalInfo,
      starData
    }) => {
      const star = stars.find((el) => el._id === starId);
      return {
        debriefID: _id,
        key: _id,
        star: star
          ? `${star.profile.firstName} ${star.profile.lastName} (${starId})`
          : '',
        debriefDate: dayjs(new Date(createdAt)).tz().format('LLL'),
        receiptType: src === FAWRY_POS_SOURCE ? 'Donation Receipts' : src,
        amount: Math.abs(amount),
        receiptDate: receiptDate || createdAt,
        receiptNo,
        trackingNumber,
        additionalInfo,
        fawryId: starData?.starInfo?.fawryUserId
      };
    }
  );
};

/**
 * Date range picker used in search form
 * @param name
 * @param label
 * @param handleChange
 * @param value
 * @returns {*}
 * @constructor
 */
const DateRangePicker = ({ name, label, handleChange, value }) => {
  const handleDateRangeChange = (dates, dateStrings) => {
    if (dates && dates.length > 0) handleChange(`${name}`, dateStrings, true);
    else {
      handleChange(`${name}`, [null, null], true);
    }
  };
  return (
    <>
      <Form.Group as={Col} sm={3} controlId={`${name}-control`}>
        <Form.Label className={styles.formLabel}>{label}</Form.Label>
        <Calendar
          handleChange={handleDateRangeChange}
          placeholder={['', '']}
          value={value[0] !== null ? [dayjs(value[0]), dayjs(value[1])] : null}
          size="large"
        />
      </Form.Group>
    </>
  );
};

/**
 * Search form used to apply filter and search deliveries
 * @param businesses
 * @param stars
 * @param hubs
 * @param handleFormChange
 * @param handleFormSubmit
 * @param handleResetForm
 * @param formLoading
 * @returns {*}
 * @constructor
 */
const SearchForm = ({
  hubs,
  stars,
  handleFormChange,
  handleFormSubmit,
  handleResetForm,
  formLoading,
  handleOnExportClick
}) => {
  const [openAccordion, setOpenAccordion] = useState(false);
  const [isStarsFieldDisabled, setIsStarsFieldDisabled] = useState(false);

  return (
    <>
      <>
        <Accordion>
          <div>
            <Accordion.Toggle
              as="a"
              variant="button"
              eventKey="0"
              onClick={() => setOpenAccordion(!openAccordion)}
              className={
                openAccordion
                  ? styles.SearchToggleOpen
                  : styles.SearchToggleClosed
              }
            >
              Search
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <div className="mt-2">
                <Formik
                  className="formContainer"
                  enableReinitialize
                  validationSchema={{}}
                  onChange={handleFormChange}
                  onSubmit={handleFormSubmit}
                  initialValues={{
                    hub:
                      !isDefaultBostaCourier([...aclMatrix.THREE_PL]) &&
                      userInfo.warehouseInfo
                        ? [
                            {
                              value: userInfo.warehouseInfo._id,
                              label: userInfo.warehouseInfo.name
                            }
                          ]
                        : '',
                    stars: [],
                    depositTypes: '',
                    receiptNumbers: '',
                    debriefDateFrom: null,
                    debriefDateTo: null,
                    receiptDateFrom: null,
                    receiptDateTo: null,
                    debriefIds: ''
                  }}
                  render={({
                    // handleSubmit,
                    // handleChange,
                    handleBlur,
                    values,
                    // touched,
                    initialValues,
                    setFieldValue,
                    // errors
                    resetForm
                  }) => (
                    <Form
                      className={styles.formWidth}
                      onSubmit={(e) => {
                        e.preventDefault();
                        handleFormSubmit();
                      }}
                    >
                      {/* Hub, Stars */}
                      <Form.Row className={styles.formRow}>
                        <Form.Group
                          as={Col}
                          sm={6}
                          controlId="destinationHubs-control"
                        >
                          <Form.Label className={styles.formLabel}>
                            Hub
                          </Form.Label>
                          <Select
                            name="hub"
                            options={[
                              { value: ALL_HUBS, label: 'All Hubs' },
                              ...formatOptions('_id', 'name', hubs)
                            ]}
                            value={values.hub || ''}
                            isClearable={isDefaultBostaCourier([
                              ...aclMatrix.THREE_PL
                            ])}
                            onChange={(evt) => {
                              if (evt?.value) {
                                handleFormChange('hub', evt.value);
                                setFieldValue('hub', evt);
                                setIsStarsFieldDisabled(evt.value === ALL_HUBS);
                              }
                            }}
                            isDisabled={
                              !isDefaultBostaCourier([...aclMatrix.THREE_PL])
                            }
                          />
                        </Form.Group>
                        <Form.Group as={Col} sm={6} controlId="stars-control">
                          <Form.Label className={styles.formLabel}>
                            Star(s)
                          </Form.Label>
                          <Select
                            name="stars"
                            options={formatOptions(
                              '_id',
                              'profile.firstName,profile.lastName',
                              stars
                            )}
                            value={values.stars}
                            isMulti
                            isClearable
                            isDisabled={isStarsFieldDisabled}
                            onChange={(evt) => {
                              handleFormChange(
                                'stars',
                                evt ? evt.map((el) => el.value) : null
                              );
                              setFieldValue('stars', evt);
                            }}
                          />
                        </Form.Group>
                      </Form.Row>
                      {/* Deposit Types */}
                      <Form.Row className={styles.formRow}>
                        <Form.Group
                          as={Col}
                          sm={6}
                          controlId="depositTypes-control"
                        >
                          <Form.Label className={styles.formLabel}>
                            Deposit Types:
                          </Form.Label>
                          <Select
                            name="depositTypes"
                            options={formatOptions(
                              null,
                              null,
                              getMoneyDebriefReportDepositTypes()
                            )}
                            value={values.depositTypes}
                            isClearable
                            isMulti
                            onChange={(evt) => {
                              handleFormChange(
                                'depositTypes',
                                evt ? evt.map((el) => el.value) : null
                              );
                              setFieldValue('depositTypes', evt);
                            }}
                          />
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          sm={6}
                          controlId="receiptNumbers-control"
                        >
                          <Form.Label className={styles.formLabel}>
                            Receipt Number(s):
                          </Form.Label>
                          <Form.Control
                            type="text"
                            value={values.receiptNumbers}
                            onBlur={handleBlur}
                            onChange={(evt) => {
                              const value = evt.currentTarget.value;
                              const newValue = value === '' ? null : value;
                              handleFormChange('receiptNumbers', newValue);
                              setFieldValue('receiptNumbers', newValue);
                            }}
                            name="receiptNumbers"
                          />
                        </Form.Group>
                      </Form.Row>
                      {/* Dates section */}
                      <Form.Row className={styles.formRow}>
                        <DateRangePicker
                          label="Debrief Date:"
                          handleChange={(name, value, isDateRange) => {
                            handleFormChange(name, value, isDateRange);
                            setFieldValue(`${name}From`, value[0]);
                            setFieldValue(`${name}To`, value[1]);
                          }}
                          name="debriefDate"
                          value={[values.debriefDateFrom, values.debriefDateTo]}
                        />
                        <DateRangePicker
                          label="Receipt Date:"
                          handleChange={(name, value, isDateRange) => {
                            handleFormChange(name, value, isDateRange);
                            setFieldValue(`${name}From`, value[0]);
                            setFieldValue(`${name}To`, value[1]);
                          }}
                          name="receiptDate"
                          value={[values.receiptDateFrom, values.receiptDateTo]}
                        />
                        <Form.Group
                          as={Col}
                          sm={6}
                          controlId="debriefIds-control"
                        >
                          <Form.Label className={styles.formLabel}>
                            Debrief IDs:
                          </Form.Label>
                          <Form.Control
                            type="text"
                            value={values.debriefIds}
                            onBlur={handleBlur}
                            onChange={(evt) => {
                              const value = evt.currentTarget.value;
                              const newValue = value === '' ? null : value;
                              handleFormChange('debriefIds', newValue);
                              setFieldValue('debriefIds', newValue);
                            }}
                            name="debriefIds"
                          />
                        </Form.Group>
                      </Form.Row>
                      {/* submit area */}
                      <div className="br-money-report__actions-container">
                        <div>
                          {' '}
                          <Button
                            type="submit"
                            className="primaryButton"
                            disabled={formLoading}
                          >
                            Filter
                          </Button>
                          <Button
                            type="button"
                            className="secondaryButton"
                            onClick={() => {
                              handleResetForm();
                              resetForm(initialValues);
                            }}
                            disabled={formLoading}
                          >
                            Clear
                          </Button>
                        </div>

                        <Button
                          type="button"
                          className="primaryButton"
                          disabled={formLoading}
                          onClick={handleOnExportClick}
                        >
                          Export
                        </Button>
                      </div>
                    </Form>
                  )}
                />
              </div>
            </Accordion.Collapse>
          </div>
        </Accordion>
      </>
    </>
  );
};

function MoneyDebriefingReport({
  hubs,
  stars,
  moneyDebriefReport,
  fetchHubs,
  fetchStars,
  fetchMoneyReport,
  clearReportData
}) {
  const [loading, setLoading] = useState(false);

  const [formValues, setFormValues] = useState({
    hub: !isDefaultBostaCourier([...aclMatrix.THREE_PL])
      ? userInfo?.warehouseInfo?._id
      : null,
    stars: null,
    depositTypes: null,
    receiptNumbers: null,
    debriefDateFrom: null,
    debriefDateTo: null,
    receiptDateFrom: null,
    receiptDateTo: null
  });
  useEffect(() => {
    fetchHubs();
  }, [fetchHubs]);
  useEffect(() => {
    fetchStars();
    return function cleanup() {
      clearReportData();
    };
  }, [fetchStars]);
  const getCurrentFilter = () => {
    return omitBy({ ...formValues /* state */ }, (value) => {
      if ((typeof value === 'string' && value === '') || value === null) {
        return true;
      }
      return Array.isArray(value) && value.length === 0;
    });
  };

  const checkRequiredFilters = (fn) => () => {
    const currentFilter = getCurrentFilter();
    if (Object.entries(currentFilter).length === 0) {
      notify('Please apply at least one filter!');
      return;
    }
    if (!currentFilter.hub) {
      notify('Hub is mandatory filter!');
      return;
    }
    fn(currentFilter);
  };

  const handleSubmit = checkRequiredFilters((filter) => {
    setLoading(true);
    if (filter.depositTypes?.length) {
      filter.depositTypes.forEach((type, index) => {
        if (type === 'Donation Receipts') {
          filter.depositTypes[index] = FAWRY_POS_SOURCE;
          return;
        }
      });
    }
    for (const key in filter) {
      if (dayjs(filter[key]).isValid() && key.includes('Date')) {
        filter[key] = FORMAT_DATE_TO_UTC(filter[key]);
      }
    }
    fetchMoneyReport({
      ...filter,
      pageId: moneyDebriefReport.pagination.pageNumber || 0
    });
    setLoading(false);
  });
  const handleFormReset = () => {
    setFormValues({
      hub: !isDefaultBostaCourier([...aclMatrix.THREE_PL])
        ? userInfo?.warehouseInfo?._id
        : null,
      stars: null,
      depositTypes: null,
      receiptNumbers: null,
      debriefDateFrom: null,
      debriefDateTo: null,
      receiptDateFrom: null,
      receiptDateTo: null
    });
    clearReportData();
  };
  const handleFormChange = (name, value, isDateRange) => {
    if (!isDateRange) {
      setFormValues({
        ...formValues,
        [name]: value
      });
    } else {
      setFormValues({
        ...formValues,
        [`${name}From`]: value[0],
        [`${name}To`]: value[1]
      });
    }
  };
  const handleTableChange = (pagingOptions) => {
    const selectedFieldsFilter = getCurrentFilter();
    fetchMoneyReport({
      pageId: pagingOptions.current - 1,
      ...selectedFieldsFilter
    });
  };

  const handleOnExportClick = async () => {
    try {
      const payload = {
        ...formValues,
        ...(formValues?.stars?.length
          ? { stars: formValues?.stars.toString() }
          : {}),
        ...(formValues?.depositTypes?.length
          ? { depositTypes: formValues?.depositTypes?.toString() }
          : {})
      };
      const { msg } = await exportMoneyDebreifReport(payload);
      notify(msg, 'success');
    } catch (error) {
      notify(error.message);
    }
  };
  return (
    <>
      <Container
        className="br-tabs-container-content"
        header={<BRHeader title="Money Debriefing Report" />}
        hideWarningBanner
        content={
          <>
            <HubManagmentContainer />
            <SearchForm
              hubs={hubs}
              stars={stars.data}
              handleResetForm={handleFormReset}
              handleFormChange={handleFormChange}
              handleFormSubmit={handleSubmit}
              handleOnExportClick={handleOnExportClick}
            />

            {/* <div className={styles.divCreate}>
            <InputGroup className={styles.inputGroup}>
              <InputGroup.Prepend>
                <InputGroup.Text id="basic-addon1">Search</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl aria-describedby="basic-addon1" sm={3}/>
            </InputGroup>
          </div> */}
            {moneyDebriefReport?.data?.length > 0 && (
              <div className="mb-4 mt-4">
                <b>Total Money Debriefing Report Items:</b>
                <span className="ml-2 ">{moneyDebriefReport?.count}</span>
              </div>
            )}
            <Table
              // rowSelection={rowSelection}
              className="my-3"
              style={{ overflow: 'auto' }}
              columns={moneyDebriefReportColumns}
              dataSource={formatDataSource(moneyDebriefReport.data, {
                stars: stars.data
              })}
              loading={loading}
              onChange={handleTableChange}
              // pagination={false}
              pagination={{
                current: moneyDebriefReport.pagination.pageNumber + 1 || 1,
                total: moneyDebriefReport.count,
                pageSize: 50,
                showSizeChanger: false
              }}
            />
          </>
        }
      />
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  fetchHubs: () => dispatch(getHubs()),
  fetchStars: () => dispatch(getVerifiedStars()),
  fetchMoneyReport: (data) => dispatch(getMoneyDebriefReport(data)),
  clearReportData: (data) => dispatch(clearCurrentMoneyDebriefReport(data))
});
const mapStateToProps = ({ hubs }) => {
  return {
    ...hubs
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MoneyDebriefingReport);
