import {
  COMPENSATION_TABLE_APPROVAL_TYPES,
  DEPARTMENTS
} from 'constants/compensation';

import './DepartmentsAndApprovalToolTip.less';

const DepartmentsAndApprovalToolTip = ({ type = 'departmentName', list }) => {
  const { DEPARTMENT } = COMPENSATION_TABLE_APPROVAL_TYPES;
  return (
    <div className="br-department-tooltip-container">
      {list.map((item, key) => (
        <div className="br-department-tooltip-content" key={key}>
          <span className="br-department-tooltip__title">
            {type === DEPARTMENT
              ? DEPARTMENTS[item?.departmentName]?.name
              : item[type]}
          </span>
          {type !== DEPARTMENT && (
            <span className="br-department-tooltip__sub_title">
              {DEPARTMENT[item?.departmentName]?.name}
            </span>
          )}
        </div>
      ))}
    </div>
  );
};

export default DepartmentsAndApprovalToolTip;
